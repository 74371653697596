body {
  font-family: Varela Round;
}

#under-construction-box {
  border: 4px solid black;
  max-width: 20em;
  min-width: 30%;
  box-shadow: black 10px 10px 0px;
  margin: auto;
  padding: 30px 20px 0px 20px;
}

#landing-container {
  text-align: center;
  margin: 20px;
}

#under-construction-heading {
  margin-bottom: 40px;
  font-size: 30px;
  font-weight: bold;
}

#under-construction-content {
  font-size: 22px;
  margin-bottom: 60px;
}

a {
  text-decoration: none;
}

.landing-button {
  width: fit-content;
  margin: 1em auto;
  padding: 3px 11px;
  background-color: rgb(3, 199, 90);
  border-radius: 10px;
  font-size: 30px;
  cursor: pointer;
  color: white;
}

.landing-button:hover {
  background-color: rgb(3, 180, 85);
  box-shadow: lightgrey 3px 3px 2px;
}
