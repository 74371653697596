@keyframes close-tooltip {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  30% {
    height: 40px;
    padding: 5px 10px;
    opacity: 0;
  }
  100% {
    height: 0px;
    padding: 0px;
    opacity: 0;
    visibility: hidden;
  }
}

/* Tooltip container */
.tooltip {
  position: relative;
}

/* Tooltip text */
.tooltip .tooltiptext {
  width: 220px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;
  margin: auto;

  animation-name: close-tooltip;
  animation-fill-mode: forwards;
  animation-duration: 1s;
  animation-delay: 5s;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  bottom: 30%;
  left: 50%;
  margin-left: -10px;
  border-width: 14px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

#hakyu-logo-container {
  margin-bottom: 2em;
}
