body {
  text-align: center;
  margin-top: 2em;
  font-family: Varela Round;
}

.sentence-wrapper {
  margin-bottom: 20px;
}

.korean-component {
  border: 3px solid transparent;
  border-radius: 10px;
  padding: 0px 8px;
  /* font-size: 60px; */
  cursor: pointer;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.korean-component:hover {
  border: 3px solid #03c75a;
  background-color: #03c75a;
  color: white;
}

.k-hovered {
  border: 3px solid #03c75a;
  background-color: #03c75a;
  color: white;
}

.k-selected {
  border: 3px solid #04bad3;
  background-color: #04bad3;
  color: white;
}

.english-component {
  border: 2px solid transparent;
  border-radius: 5px;
  padding: 0px 4px;
  /* font-size: 30px; */
  cursor: default;
}

.e-hovered {
  border: 2px solid #03c75a;
}

.e-selected {
  border: 2px solid #04bad3;
}

#sentence-passed {
  padding-left: 20px;
}

.generic-button {
  width: fit-content;
  margin-top: 0.5em;
  padding: 3px 11px;
  background-color: rgb(255, 112, 87);
  border-radius: 10px;
  font-size: 30px;
  cursor: pointer;
  color: white;
}

.generic-button:hover {
  background-color: rgb(240, 112, 80);
  box-shadow: lightgrey 3px 3px 2px;
}

.generic-korean-word {
  min-width: 50px;
  display: inline-flex;
  border-bottom: 4px solid black;
  font-size: 30px;
  padding: 0px 10px;
}

@media screen and (max-width: 1000px) {
  .korean-component {
    font-size: 22px;
  }
  .english-component {
    font-size: 22px;
  }
  #principle-area {
    font-size: 22px;
  }
  #sentence-passed {
    font-size: 22px;
  }
  .generic-button {
    font-size: 20px;
  }
  .generic-korean-word {
    font-size: 20px;
    padding: 0px 5px;
    border-bottom: 2px solid black;
  }
  .generic-english-word {
    border-bottom: 2px solid black;
    width: 50px;
    display: inline-flex;
  }
}
@media screen and (min-width: 1001px) {
  .korean-component {
    font-size: 60px;
  }
  .english-component {
    font-size: 30px;
  }
  #principle-area {
    font-size: 30px;
  }
  #sentence-passed {
    font-size: 60px;
  }
  .generic-english-word {
    border-bottom: 4px solid black;
    width: 100px;
    display: inline-flex;
  }
}
